import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Account, Currency, CurrencyType } from '@ledgerhq/live-app-sdk';
import { catchError, map, Subject, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CustomToastComponent } from '../../components/custom-toast/custom-toast.component';
import { ImagePreloadDirective } from '../../directives/image-preload.directive';
import { ExtendedAccount } from '../../models/extended-account.model';
import { GenericResponse } from '../../models/generic-response.model';
import { Feature, IzanamiResponse } from '../../models/izanami-response.model';
import { User } from '../../models/user.model';
import { AccountListResponse, WaltioAccount } from '../../models/waltio-account.model';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { IntercomService } from '../../services/intercom.service';
import { LedgerService } from '../../services/ledger.service';
import { WaltioService } from '../../services/waltio.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from '@auth0/auth0-angular';
import { RouterModule } from '@angular/router';
import mixpanel from 'mixpanel-browser';
import { AlternativeCurrencyNames } from 'src/app/constants/alternative-currency-names.constant';

@Component({
  selector: `app-accounts-page`,
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule,
    ImagePreloadDirective,
    TruncatePipe,
    CustomToastComponent,
  ],
  templateUrl: `./accounts-page.component.html`,
  styleUrls: [`./accounts-page.component.scss`],
})
export class AccountsPageComponent implements OnInit, OnDestroy {
  responsiveBreakpoint: number;

  showToast = false;
  toastType = `success`;
  toastError: any;
  displayedColumns: string[] = [`token`, `address`, `action`];

  currencies: Map<string, Currency> = new Map([]);
  extendedAccounts: ExtendedAccount[] = [];
  unsupportedAccounts: string[] = [];
  features: Feature[] = [];
  waltioAvailableAccounts: WaltioAccount[] = [];

  isMobile = false;
  isTablet = false;
  isDesktop = false;

  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    private readonly waltioService: WaltioService,
    private readonly ledgerService: LedgerService,
    private readonly authService: AuthService,
    private readonly intercomService: IntercomService,
    private readonly deviceService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();

    this.intercomService.updatePageName(`accounts`);

    this.getUser();
    this.getWaltioAccounts();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getUser(): void {
    this.waltioService
      .getUser()
      .pipe(
        takeUntil(this.destroy$),
        map((user: User) => {
          if (user) {
            mixpanel.identify(user.id);
          }

          if (!user.acquisitionChannel) {
            this.setUserAcquisitionChannel();
          }
        })
      )
      .subscribe();
  }

  setUserAcquisitionChannel(): void {
    this.waltioService.setUserAcquisitionChannel(`LEDGER_LIVE`).pipe(takeUntil(this.destroy$)).subscribe();
  }

  getWaltioAccounts(): void {
    this.waltioService
      .getWaltioAvailableAccounts()
      .pipe(
        takeUntil(this.destroy$),
        map((response: AccountListResponse) => {
          this.waltioAvailableAccounts = [
            ...response.blockchain,
            ...response.platform,
            ...response.service,
            ...response.wallet,
          ];

          this.getFeatures();
        })
      )
      .subscribe();
  }

  getFeatures(): void {
    this.waltioService
      .getFeatures()
      .pipe(
        takeUntil(this.destroy$),
        map((izanamiResponse: IzanamiResponse) => {
          this.features = izanamiResponse.results;

          this.getCurrencies();
        })
      )
      .subscribe();
  }

  getCurrencies(): Promise<void> {
    return this.ledgerService.getCurrencies().then((currencies: Currency[]) => {
      currencies.forEach((currency: Currency) => {     
        this.currencies.set(currency.id, currency);
      });

      // Manually add missing currencies and accounts
      this.currencies.set(`cardano`, {
        id: `cardano`,
        type: CurrencyType.CryptoCurrency,
        name: `Cardano`,
        ticker: `ADA`,
        family: `cardano`,
        color: ``,
        units: [],
      });
      this.currencies.set(`hedera`, {
        id: `hedera`,
        type: CurrencyType.CryptoCurrency,
        name: `Hedera`,
        ticker: `HBAR`,
        family: `hedera`,
        color: ``,
        units: [],
      });
      this.currencies.set(`near`, {
        id: `near`,
        type: CurrencyType.CryptoCurrency,
        name: `NEAR Protocol`,
        ticker: `NEAR`,
        family: `near`,
        color: ``,
        units: [],
      });
      this.currencies.set(`solana`, {
        id: `solana`,
        type: CurrencyType.CryptoCurrency,
        name: `Solana`,
        ticker: `SOL`,
        family: `solana`,
        color: ``,
        units: [],
      });
      this.currencies.set(`elrond`, {
        id: `elrond`,
        type: CurrencyType.CryptoCurrency,
        name: `Multivers X (ex Elrond)`,
        ticker: `EGLD`,
        family: `elrond`,
        color: ``,
        units: [],
      });
      this.currencies.set(`dogecoin`, {
        id: `dogecoin`,
        type: CurrencyType.CryptoCurrency,
        name: `Dogecoin`,
        ticker: `DOGE`,
        family: `dogecoin`,
        color: ``,
        units: [],
      });

      this.getAccounts();
    });
  }

  getAccounts(): Promise<void> {
    return this.ledgerService.getAccounts().then((accounts: Account[]) => {
      this.extendedAccounts = accounts
        .filter((account: Account) => account.currency && !account.currency.includes(`/`))
        .map((account: Account) => {
          return {
            account,
            checking: false,
            adding: false,
            added: false,
            realAddress:
              account.currency === `bitcoin` || account.currency === `cardano`
                ? this.extractXPUB(account, `${account.currency}:`)
                : account.address,
          };
        });
      
      this.getUnsupportedAccounts();
      this.getAddedAddress();
    });
  }

  getAddedAddress(): void {
    this.extendedAccounts.forEach((extendedAccount: ExtendedAccount) => {
      const platform = this.getWaltioAccountKey(extendedAccount.account);

      if (this.isAccountSupported(platform)) {
        extendedAccount.checking = true;

        this.waltioService
          .checkAddress(platform, extendedAccount.realAddress)
          .pipe(
            takeUntil(this.destroy$),
            map((res: GenericResponse) => {
              extendedAccount.checking = false;
              if (res.success) {
                extendedAccount.added = true;
              } else {
                extendedAccount.added = false;
              }
            }),
            catchError((error: any) => {
              this.toastError = null;
              this.toastType = `error`;
              this.showToast = true;
              return error;
            })
          )
          .subscribe();
      }
    });
  }

  checkAddress(extendedAccount: ExtendedAccount, index: number): void {
    const platform = this.getWaltioAccountKey(extendedAccount.account);

    if (this.isAccountSupported(platform)) {
      this.waltioService
        .checkAddress(platform, extendedAccount.realAddress)
        .pipe(
          takeUntil(this.destroy$),
          map((res: GenericResponse) => {
            if (!res.success) {
              this.addWallet(extendedAccount, index);
            }
          }),
          catchError((error: any) => {
            this.toastError = null;
            this.toastType = `error`;
            this.showToast = true;
            return error;
          })
        )
        .subscribe();
    }
  }

  getUnsupportedAccounts(): void {
    this.extendedAccounts.forEach((extendedAccount: ExtendedAccount) => {
      if (
        !this.unsupportedAccounts.includes(extendedAccount.account.currency) &&
        !this.isAccountSupported(this.getWaltioAccountKey(extendedAccount.account))
      ) {
        this.unsupportedAccounts.push(extendedAccount.account.currency);
      }
    });
  }

  isAccountSupported(account: string): boolean {
    const accountFeature: Feature = this.features.find(
      (feature: Feature) => feature.id === `${environment.features.pattern}accounts:${account}`
    );

    const apiFeature = this.features.find(
      (feature: Feature) => feature.id === `${environment.features.pattern}accounts:${account}:api`
    );

    return accountFeature?.enabled || apiFeature?.enabled;
  }

  addWallet(extendedAccount: ExtendedAccount, index: number): void {
    this.extendedAccounts[index].adding = true;

    this.waltioService
      .createAPI(
        this.getWaltioAccountKey(extendedAccount.account),
        extendedAccount.realAddress,
        extendedAccount.account.name
      )
      .pipe(
        takeUntil(this.destroy$),
        map(() => {
          this.toastType = `success`;
          this.showToast = true;

          this.extendedAccounts[index].adding = false;
          this.extendedAccounts[index].added = true;
        }),
        catchError((error: any) => {
          this.toastError = error;
          this.toastType = `error`;
          this.showToast = true;
          this.extendedAccounts[index].adding = false;

          return error;
        })
      )
      .subscribe();
  }

  goToFeedback(): void {
    window.open(`https://feedback.waltio.com/`, `_blank`);
  }

  goToWaltio(): void {
    window.open(`https://tax.waltio.com/`, `_blank`);
  }

  extractXPUB(account: Account, prefix: string): string {
    return account.id?.split(prefix)[1]?.split(`:`)[0];
  }

  getWaltioAccountKey(account: Account): string {
    let res;
    const candidates = this.waltioAvailableAccounts.filter((waltioAccount: WaltioAccount) => {
      return waltioAccount.name.toLowerCase().includes(this.currencies.get(account.currency)?.name.toLowerCase());
    });

    if (candidates.length === 1) {
      res = candidates[0].key;
    } else {
      res = this.waltioAvailableAccounts.find((waltioAccount: WaltioAccount) => {
        return waltioAccount.name.toLowerCase() === account.currency || waltioAccount.name.toLowerCase() === AlternativeCurrencyNames.get(account.currency)?.toLowerCase();
      })?.key;
    }
   
    return res;
  }

  logout(): void {
    this.authService.logout({
      logoutParams: {
        returnTo: environment.production ? `https://ledgerlive.waltio.com` : `https://ledgerlive-dev.waltio.com`,
      },
    });
  }
}
